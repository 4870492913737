.user_info {
  border-radius: 8px;
  height: fit-content;

}

.notifications-page {
  flex-grow: 1;
  width: 100%;
  flex-shrink: 1;
  height: 100vh;
  overflow-y: auto;
}

.user_info {
  flex-grow: 1;
  flex-shrink: 1;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  z-index: 1;
}

.top-bar p {
  margin: 8px;
}

.notifications_card {
  flex-direction: row;
  height: 80px;
  border: 0px;
  border-radius: 15px;
  box-shadow: 0px 2px 4px 3px rgba(0, 0, 0, 0.10);
}

.notifications_card .card-header {
  padding: .5rem 1rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 0px solid rgba(0, 0, 0, .125);
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.notifications_card .card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
}

.notifications_card .card-footer {
  font-size: 12px;
  font-weight: 200;
  padding: .5rem 1rem;
  margin-bottom: 0;
  background-color: transparent;

}

.notifications_card:hover {
  background-color: #FFDDDD;
}

/*----------3 dots ----------*/
.notifications-page .menu-nav {
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.notifications-page .three-dots:after {
  cursor: pointer;
  color: black;
  content: '\22EE';
  font-size: 20px;
  padding: 0 5px;
}

.notifications-page .dropdown a {
  text-decoration: none;
  color: #5E5E5E;
  font-size: 12px;
  padding: 0px;
}

.notifications-page .dropdown a div {
  padding: 5px 10px;
  border: .5px solid rgb(219, 215, 215);
}

.notifications-page .like_card {
  flex-direction: row;
  height: 100px;
}

.notifications-page .dropdown {
  position: absolute;
  right: 40px;
  top: 20px;
  background-color: white;
  border: 1px solid rgb(224, 223, 223);
  outline: none;
  opacity: 0;
  /*z-index: -1;
    max-height: 0;
    transition: opacity 0.1s, z-index 0.1s; max-height: 5s;*/
  text-align: center;
  padding: 0px;
}

.notifications-page .cancel {
  color: #D90000;
  background-color: #f5f5f5;
}

.notifications-page .cancel:hover {
  color: #D90000;
}

.notifications-page .dropdown-container:focus {
  outline: none;
}

.notifications-page .dropdown-container:focus .dropdown {
  opacity: 1;
  z-index: 100;
  max-height: 100vh;
  transition: opacity 0.2s, z-index 0.2s;
  max-height: 0.2s;
}

.close_button {
  cursor: pointer;
}

.close-button {
  margin: 0px auto;
  -webkit-transition: -webkit-transform 1s ease-in-out;
  transition: transform 1s ease-in-out;
  transform-origin: center;
  transform-style: preserve-3D;
}

.close-button:hover {
  -webkit-transform: rotate(90deg);
  transform: rotate(180deg);
}

.notifications_card.notify {
  width: 50% !important;
}

.profile_div {
  right: 0px;
  position: fixed;
  width: 400px;
  height: calc(100vh - 80px); /* Ensure spaces around the minus sign */
  overflow: auto;
}


@media only screen and (max-width: 600px) {
  .top-bar {
    z-index: 0;
  }

  .profile_div {
    top: 0px;
    right: 0px;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: auto;
    min-height: -webkit-fill-available;
  }

  .notifications_card {
    width: 100% !important;
  }
}