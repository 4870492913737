.btn-primary:hover {
    color: #fff;
    background-color: grey !important;
    border-color: grey;
}

.btn-primary:active {
    color: #fff;
    background-color: grey !important;
    border-color: grey;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
    color: #fff;
    background-color: grey !important;
    border-color: grey;
    box-shadow: 0 0 0 0.25rem rgba(165, 181, 204, 0.5);

}

li.active_val3 {
    border: 1px solid #aaabab;
    border-radius: 3px;
    background-color: #f1f7f8;
    color: grey;
}

li.active_val2 {
    border: 1px solid #aaabab;
    border-radius: 3px;
    background-color: #f1f7f8;
    color: grey;
}

li.active_val1 {
    border: 1px solid #aaabab;
    border-radius: 3px;
    background-color: #f1f7f8;
    color: gray;
}

li.active_val {
    border: 1px solid #aaabab;
    border-radius: 3px;
    background-color: #f1f7f8;
    color: gray;
}

.login_buttons {
    cursor: pointer;
    color: #D90000;
}

.signup {
    background-color: #D90000;
    color: white;
}
/* Example CSS for the modal-like popup */
.notification-modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
  }
  
  .notification-modal.show {
    display: flex;
  }
  
  .notification-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }
  
  .notification-modal-content button {
    padding: 8px 16px;
    background-color: #D90000;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  /* Additional styles for the rest of your application */
  