ul.nav.nav-tabs {
  justify-content: space-around;
  cursor: pointer;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #f1f1f1;
  border-radius: 0px;
  border-color: #f1f1f1;
  color: #D90000 !important;
  border-bottom: 2px solid #D90000;
}

.nav-link {
  color: #696e72;
}

.request-page {
  flex-grow: 1;
  width: 100%;
  flex-shrink: 1
}

.user_info {
  flex-grow: 1;
  flex-shrink: 1;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

}

.top-bar p {
  margin: 8px;
}

/*----------3 dots ----------*/
.chat-page .menu-nav {
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.chat-page .three-dots:after {
  cursor: pointer;
  color: black;
  content: '\22EE';
  font-size: 20px;
  padding: 0 5px;
}

.chat-page .dropdown a {
  text-decoration: none;
  color: #5E5E5E;
  font-size: 12px;
  padding: 0px;
}

.chat-page .dropdown a div {
  padding: 5px 10px;
  border: .5px solid rgb(219, 215, 215);
}

.chat-page .like_card {
  flex-direction: row;
  height: 100px;
}

.chat-page .dropdown {
  position: absolute;
  right: 40px;
  top: 20px;
  background-color: white;
  border: 1px solid rgb(224, 223, 223);
  outline: none;
  opacity: 0;
  /*z-index: -1;
    max-height: 0;
    transition: opacity 0.1s, z-index 0.1s; max-height: 5s;*/
  text-align: center;
  padding: 0px;
}

.chat-page .cancel {
  color: #D90000;
  background-color: #f5f5f5;
}

.chat-page .cancel:hover {
  color: #D90000;
}

.chat-page .dropdown-container:focus {
  outline: none;
}

.chat-page .dropdown-container:focus .dropdown {
  opacity: 1;
  z-index: 100;
  max-height: 100vh;
  transition: opacity 0.2s, z-index 0.2s;
  max-height: 0.2s;
}

.display {
  display: none;
}

.display1 {
  display: block;
}

.display2 {
  display: none !important;
}

.request_card {
  flex-direction: row;
  height: 100px;
  border-radius: 10px;
  border-radius: 15px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
}

.loc_request {
  line-height: 10px;
}

.close_button {
  cursor: pointer;
}

.close-button {
  margin: 0px auto;
  -webkit-transition: -webkit-transform 1s ease-in-out;
  transition: transform 1s ease-in-out;
  transform-origin: center;
  transform-style: preserve-3D;
}

.close-button:hover {
  -webkit-transform: rotate(90deg);
  transform: rotate(180deg);
}

.profile_div {
  right: 0px;
  position: fixed;
  width: 400px;
  height: calc(100vh - 80px); /* Ensure spaces around the minus sign */
  overflow: auto;
}

@media only screen and (max-width: 600px) {
  .top-bar {
    z-index: 0;
  }

  .profile_div {
    top: 0px;
    right: 0px;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: auto;
    min-height: -webkit-fill-available;
  }
  
  .req-card-body{
      padding-left: 0px !important;
      padding-right: 0px !important;
  }
}