.AddPetloadingImage {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1550;
  background: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.AddPetloadingImage div {
  height: 120px !important;
}

.AddPetloadingImage div {
  height: 160px !important;
}

.AddloadingImage {
  display: flex;
  align-items: center;
  justify-content: center; 
  height: 100%;
  width: 100%;
}

/* 
.AddloadingImage div {
  height: 200px !important;
} */