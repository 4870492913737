.welcome_page{
    background-image: url('../../assets/images/welcome_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
}
p.sub_text {
    font-size: 13px;
    color: #747F80;
}
p.sub_p{
    margin: auto;
    color: #747F80;
    font-size: 14px;
}

.agree_btn {
    display: block;
    background-color: #D90000;
    margin: auto;
    border-color: #D90000;
}
.form_check {
    display: block;
    min-height: 1.5rem;
    font-size: 14px;
    padding-left: 0em;
    margin-bottom: .125rem;
}
.wel_check {
    color: #D90000;
    padding: 0px 5px 5px 0px;
}
.terms_content{
    font-size: 15px;
}
.agree_btn:disabled {
    color: #fff;
    background-color: #aaacaf;
    border-color: #a5a6a7;
}
input[type=checkbox]:required:checked ~
input[type=checkbox]:required:checked ~
button:disabled {
  display:none;
}

input[type=checkbox]:required:not(:checked) ~ button:not(:disabled) {
  display:none;
}