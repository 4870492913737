/* landing page css start */

.logo {
    position: absolute;
    top: 0;
    z-index: -1;
}
.site_logo{
    background-color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    width: 200px !important;
}
.footer_logo{
    background-color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    width: 150px !important;
}
ul {
    display: contents;
    list-style-type: none;
}

.menu li {
    padding: 2rem 4rem;
}

.menu li a {
    text-decoration: none;
    color: white;
}

.footer {
    background-color: black;
}

.foot_1 {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    text-align: center;
    padding: 10px;
    align-items: center;
}

.foot_1 li {
    padding: 8px;
    font-size: 14px;
}

.foot_1 li a {
    text-decoration: none;
    color: white;
}

.foot_2 {
    border-top: 1px solid white;
    padding: 3px;
}

.head {
    text-align: center;
}

i.fab.fa_icons {
    color: #D90000;
    background-color: white;
    padding: 10px;
    border-radius: 30px;
    margin: 10px;
}

.foot_2 p {
    margin-bottom: 0px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
}

.foot_1 p {
    margin-bottom: 0px;
}

.main_bg {
    background-image: url("../images/banner_img.png");
    height: 900px;
    background-size: cover;
}

i.fab.fa-facebook-f {
    padding: 10px 14px;
}

.main_card {
    justify-content: space-around;
    padding: 2rem 3rem;
    z-index: -1;
}

.card_body {
    width: 20% !important;
    padding: 20px 0px 0px 0px;
    border-radius: 8px;
    box-shadow: 0px 0px 3px 1px #D900007a;
}

h5.card-title {
    font-size: 18px;
}

p.card-text {
    font-size: 14px;
}

.ios_icon {
    margin-left: 1rem;
}

.card.card_body img {
    height: 70px;
    /* width: 70px; */
    display: block;
    margin: auto;
}

.banner_text {
    position: absolute;
    padding: 25px;
    top: 12rem;
    left: 10rem;
    background-color: #c4c4c43b;
    border-radius: 30px;
}

.banner_text h3 {
    color: white;
    text-align: center;
    padding: 10px 0px;
}

/* hr {
    color: white;
} */

.reg_button {
    font-size: 14px;
}

.banner_text h3 span {
    color: #D90000;
}

.gender_a {
    padding: 0 1rem;
}

.gender_b {
    padding: 0 1rem;
}

.gender_1 {
    padding-left: 3rem;
}

.gender_2 {
    padding: 0rem 2rem;
}

.gender_3 {
    padding-left: 0rem;
}

.gender_4 {
    padding: 0rem 2rem;
}

img.iphone_img {
    margin-right: -1.5rem;
    position: relative;
    margin-left: -1.5rem;
}

.android,
.ios {
    font-size: 12px;
}

i.fa.fa-android {
    font-size: 40px;
}

i.fab.fa-apple {
    font-size: 40px;
}

.text {
    color: #0f001a;
    opacity: 0.7;
}

button.btn.btn-danger.w-50.mb-1.reg_button {
    background-color: #D90000;
}

.text-success.pass_val {
    padding: 2px;
    font-size: 12px;
    margin: 10px;
}

li.active_val,
.active_val1,
.active_val2,
.active_val3 {
    background-color: #dee8e5;
    font-size: 12px;
    padding: 2px 4px;
    margin: 10px;
}

.member_login {
    background-color: #4f4e4e;
    border-color: #4f4e4e;
}

i.fa.fa-play.btn-icon-prepend.mdi-36px {
    font-size: 38px;
}

a.px-5.text-white.nav-link:focus {
    outline: 0px;
}

.footer_links {
    text-decoration: none;
    color: white;
    padding: 9px;
    font-size: 14px;
}

.footer_links:hover {
    color: #D90000;
}

.footer_bottom {
    margin-bottom: 0px;
}

a.for_pass {
    text-decoration: none;
    color: black;
}

/* .social_buttons .google_button {
    width: 67% !important;
} */
/* .social_buttons button {
    width: 40% !important;
} */

button.facebook_social_button {
    margin-bottom: 4rem;
}

/* landing page css end */


/* register css start*/
.btn-primary:hover {
    color: #fff;
    background-color: grey !important;
    border-color: grey;
}

.reg_bg {
    background-image: url("../images/regbg.png");
    height: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: grid;
    align-items: flex-end;
    height: 100vh;
}

.login_button {
    background-color: #D90000;
    border-color: #D90000;
}

.register_button {
    background-color: #D90000;
    border-color: #D90000;
}

ul#pills-tab {
    float: right;
}

.nav-pills .nav-link {
    background: 0 0;
    border: 1px solid #D90000;
    border-radius: 0;
    color: #D90000;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #D90000;
}

.content_login {
    padding: 0px 6rem;
}

button.social_icons {
    margin: 10px 0px !important;
    width: 67% !important;
    font-size: 16px !important;
}

/* .social_buttons button, .google_button {
    margin: auto !important;
} */
button.social_icons {
    margin-bottom: 10px !important;
    margin-top: 10px !important;
}

span.error_msg {
    color: #D90000;
    font-size: 14px;
}

.country_code {
    display: flex;
}

p.select_placeholder {
    margin: 0;
}

/* .css-319lph-ValueContainer{
    padding: 0px 20px 0px 5px !important;
} */
.css-1s2u09g-control {
    margin: 0px 5px 0px 0px;
}

.css-qc6sy-singleValue {
    overflow: initial !important;
}

.css-tlfecz-indicatorContainer {
    padding: 0px !important;
}

input#formBasicNumber {
    width: 75%;
}

div#react-select-3-listbox {
    width: 200px !important;
    font-size: 14px !important;
}

input.col-9.number_field.form-control {
    width: 80%;
}

.select_country.col-3 {
    width: 20%;
}

.select_country_code.col-3 {
    width: 25%;
}

.facebook_social_button {
    background-color: #1877F2;
    border-color: #1877F2;
    color: white;
    padding: 6px;
    margin-top: 1rem !important;
    border-radius: 3px;
}

.google_social_button {
    background-color: #1877F2;
    border-color: #1877F2;
    color: white;
    padding: 6px;
    border-radius: 3px;
}

.facebook_fafa {
    border-radius: 20px;
    padding: 4px 6px 0px 6px !important;
}

.fa-google {
    /* background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat; */
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    background-color: white;
}

/* register css end */


/* Pages CSS start */

.otp_page {
    background-image: url("../images/otp_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    background-position: center;
}

.security_page {
    background-image: url("../images/security_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    background-position: center;
}

.emailverify_page {
    background-image: url("../images/email_verifybg.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    background-position: center;
}

.otp_input div {
    justify-content: center;
}

.otp_input input {
    outline: none;
    background-color: #f1f7f8;
}

.inputs input {
    width: 40px;
    border: none;
    border-radius: 0px !important;
    height: 40px;
    border-bottom: 1px solid;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0
}

.card-2 {
    background-color: #fff;
    padding: 10px;
    width: 350px;
    height: 100px;
    bottom: -50px;
    left: 20px;
    position: absolute;
    border-radius: 5px
}

.card-2 .content {
    margin-top: 50px
}

.card-2 .content a {
    color: black
}

.validate {
    height: 40px;
    background-color: #D90000;
    border: 1px solid #D90000;
    width: 140px
}

.pass_text {
    float: right;
    margin-top: -4rem;
    margin-right: 15px;
    cursor: pointer;
    color: #D90000;
    border: none;
    background: none;
}

.pass_text1 {
    float: right;
    margin-top: -4rem;
    margin-right: 15px;
    cursor: pointer;
    color: #D90000;
    border: none;
    background: none;
}

.content {
    margin-top: 0px !important;
    padding-top: 2rem !important;
    background-color: #f1f7f8;
    display: grid;
    align-items: center;
}

.content_login {
    margin-top: 0px !important;
    padding-top: 2rem !important;
    background-color: #f1f7f8;
}

.btn_disabled {
    border: 1px;
    background-color: #D90000;
    color: white;
    box-shadow: 0px 4px 2px 0px;
}

.pass_fields {
    border: none;
    border-bottom: 1px solid #8f8f8f;
    border-radius: 0px;
    background-color: #f1f7f8;
}

.emailver_btn {
    color: #D90000;
    text-align: center !important;
}

button.btn-close.close_btn {
    float: right;
    position: absolute;
    top: 1rem;
    right: 2rem;
}

span.error_msg.pp {
    margin-left: 6rem;
}

.modal-content {
    width: auto;
    border-radius: 10px;
}

.modal-body p {
    text-align: center;
    font-size: 14px;
    color: #747f80;
}

.modal-header .btn-close {
    padding: 0px;
    margin: 0px;
}

div#contained-modal-title-vcenter {
    font-size: 14px;
    margin: auto;
}

.modal-footer {
    display: flex;
    justify-content: space-around;
}

.modal-dialog.modal-lg.modal-dialog-centered {
    display: block;
    margin: auto;
    width: 25%;
    top: 10rem;
}

.no_btn {
    color: #D90000;
    background-color: white;
    border-color: white;
}

.yes_btn {
    color: #747f80;
    background-color: white;
    border-color: white;
}

.basic_check {
    font-size: 14px;
    /* display: flex; */
    /* width: max-content; */
    /* margin-left: 7rem; */
}

.terms_policy {
    color: #D90000;
    text-decoration: none;
}

.terms_policy:hover {
    color: #D90000;
    text-decoration: none;
}

.resend_btn.btn.btn-light:focus {
    box-shadow: none;
}

.resend_btn {
    background-color: #f1f7f8;
}

.resend_btn.btn.btn-link:focus {
    box-shadow: none;
}

/* Pages CSS end  */

span.passtext-1 {
    float: right;
    margin-top: -30px;
    margin-right: 5px;
    cursor: pointer;
    color: #D90000;
}

span.passtext-2 {
    float: right;
    margin-top: -30px;
    margin-right: 5px;
    cursor: pointer;
    color: #D90000;
}

.toastify.on.toastify-right.toastify-top {
    background: #D90000 !important;
    min-width: fit-content;
}
.toast-close{
    opacity: 1 !important;
}
.range-wrap {
    position: relative;
    margin: 0 auto 3rem;
}

.range {
    width: 100%;
}

.bubble {
    background: #D90000;
    color: white;
    padding: 4px 12px;
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
}

.bubble::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 2px;
    background: #D90000;
    top: -1px;
    left: 50%;
}


.react-apple-signin-auth-btn-light {
    border-color: #f1f7f8 !important;
}

.loadingImage {
    width: 201px;
    position: absolute;
    z-index: 1550;
    top: 165px;
    left: 580px;
}

span.error_msg_front {
    color: white;
    display: block;
    /* margin: auto; */
    text-align: center;
}

span.gender_1 label {
    margin-left: 5px;
}

span.gender_2 label {
    margin-left: 5px;
}

span.gender_3 label {
    margin-left: 5px;
}

span.gender_4 label {
    margin-left: 5px;
}

i.fab.fa-instagram.fa_icons {
    font-weight: 600;
}

/* input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
} */

input[type='radio']:checked:after {
    width: 14px;
    height: 14px;
    border-radius: 15px;
    top: 0px;
    left: -1px;
    position: relative;
    background-color: #D90000;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

option:checked {
    color: #D90000;
}

.forgot_field {
    border: none;
}

button.back_button.btn.btn-danger {
    position: relative;
    left: -5rem;
    top: -2rem;
    background-color: #D90000 !important;
}

.password_val {
    display: inline-flex;
}

.home_data {
    background-color: #000000;
}

.pt_test {
    text-decoration: none;
    color: white;
    font-size: 14px;
}

.pt_test:hover {
    color: #D90000;
}

.blink-soft {
    animation: blinker 1.2s linear infinite;
    color: #D90000;
    font-size: 18px;
    font-weight: 500;
}

@keyframes blinker {
    50% {
        opacity: 0.5;
    }
}

.aboutpage_bg {
    background-image: url('../images/aboutpage_bgg.png');
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position: center; */
    height: 100vh;
}

.active {
    background-color: #D90000;
    color: rgb(255, 255, 255) !important;
    border-radius: 20px;
}

.member_bg {
    background-image: url('../images/membership_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    background-position: center;
}

.main_title {
    text-shadow: 0 0 3px #D90000, 0 0 15px #D90000;
}

.progress.progress-striped.active {
    background: #f0f7f800;
}

.goog_pass_bg {
    background-image: url('../images/google_pass.png');
    background-position: center;
    background-size: cover;
    height: 100vh;
}
.send_btn{
    background-color: #ff4545;
    border-color: #ff4545;
}
.send_btn:hover{
    border-color: #ff4545;
}

.tickets_list_chidren{
    border: 1px solid #D90000;
    color: #FFF;
    background-color: #D90000;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;

}

.tickets_list_chidren_notactive:hover{
    border: 1px solid #D90000;
    color: #FFF;
    background-color: #D90000;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}

.tickets_list_chidren_notactive{
    border: 1px solid #D90000;
    color: #D90000;
    background-color: #fff;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}

.chat_send {
    background: none;
    border: none;
  }
  
  .chat_send img {
    width: 33px;
  }
  
  .chat_send:hover {
    background-color: white !important;
  }
  
  .chat_send:disabled {
    background-color: white !important;
  }

  .ul.nav.nav-tabs{
    flex-direction: row !important;
  }

  .settings-page ul li:hover{
    background-color: none !important;
  }
  .sendMsg_1{
    position: fixed;
    display: flex;
    width: 40%;
    bottom: 0;
    background-color: #bbbbbb;
    padding: 0.3rem 3rem;
  }
  .sendMsg_2{
    position: fixed;
    display: flex;
    width: 27%;
    bottom: 10px;
  }
  .membership_bg{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 767px) {
    .foot_1 {
        grid-template-columns: auto !important;
        grid-gap: 0.5rem;
    }
    .modal-dialog.modal-lg.modal-dialog-centered {
        width: 75%;
    }
    .foot_1 ul {
        display: inline;
    }

    .main_bg {
            height: 300px;
            background-size: contain;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
    }

    a.col-6.navbar-brand img {
        width: 100%;
    }

    .navbar-toggler {
        padding: 5px;
        font-size: 12px;
        line-height: 1;
        background-color: white;
        border: 1px solid transparent;
        border-radius: .25rem;
        transition: box-shadow .15s ease-in-out;
    }

    .nav-link {
        padding: 4px;
    }

    .main_card {
        display: grid !important;
        margin-top: 1px;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        padding: 1rem 1.5rem;
    }

    .card_body {
        width: auto !important;
        margin: 0 0 1rem 0;
    }

    .banner_text {
        top: 3rem;
        font-size: 12px;
        left: 0rem;
        padding: 5px;
        position: initial;
        margin-top: 2rem;
    }
    .hr-margin {
        margin: 0.5rem; /* Same as Bootstrap's m-2 */
    }
    .banner_text h3{
        padding: 5px 0px;
    }
    .store-btn{
        display: none !important;
    }
    .site_logo{
        width: 150px !important;
    }
    div#navbarScroll{
        background-color: #000000;
        padding: 1rem;
    }
    .landing_img{
        width: 80%;
        margin-bottom: 1rem;
    }
    .landing_btn{
        display: flex;
    }
    .landing_btn button{
        margin: 5px;
    }
    .footer_bottom{
        padding: 0;
    }
    .membership_bg{
        display: flex;
        align-items: start;
        justify-content: center;
    }
    .member_bg{
        background-position: top;
        background-size: cover;
        height: 300px;
    }
    .main_title{
        font-size: 3rem !important;
    }
    .aboutpage_bg{
        background-size: contain;
        height: 280px;
    }
    .content_login{
        padding: 0rem 1.5rem;
    }
    li.active_val, .active_val1, .active_val2, .active_val3{
        margin: 6px;
    }
    .nav-pills .nav-link{
        padding: 6px 16px;
    }
    input.col-9.number_field.form-control {
        width: 75%;
    }
    .reg_bg{
        display: none;
    }
    .otp_page{
        display: none;
    }
    .security_page{
        display: none;
    }
    .emailverify_page{
        display: none;
    }
    .welcome_page{
        display: none;
    }
    .display_bg{
        display: none;
    }
    .looking_bg{
        display: none;
    }
    button.back_button.btn.btn-danger{
        left: 0;
    }
    .about_page{
        display: none;
    }
    .photos_bg{
        display: none;
    }
    .img-box {
        margin: 0rem 0rem 2rem 0rem !important;
        padding: 10px;
    }
    .moreabout_page{
        display: none;
    }
    .looking_hwp{
        padding: .375rem 2rem .375rem 0rem;
    }
    .looking_hw{
        width: 47%;
    }
    .hobbies_page{
        display: none;
    }
    .selfiePic{
        display: none;
    }
    .sendMsg_1{
        width: 100%;
    }
    .top-bar{
        display: none !important;
    }
}
div#three-dots {
    display: none !important;
}
.payment-bottom-card .text-card:hover {
    transform: scale(1.05);
    color: #D90000;
}