.number-slide {
    background-image: url('../../assets/images/viewers-pic.png');
    background-size:cover;
   background-position: center;
   color: white;
    position: relative;
    width: 227.91px;
height: 287.3px;
    display: flex;
    flex-direction: column;
    justify-content: end;
}
.number-slide i{
    color:#D90000;
    padding-left: 5px;
}
.number-slide p{
    padding-left: 5px;
}
.number-slide .profile-name{
    margin-bottom: 2px;
    padding-left: 8px;
}

.viewers-card{
    margin-left:300px;
    
    overflow: hidden;
}
.viewers-page{
    flex-grow: 1;
    width: 100%;
    flex-shrink:1
}
.user_info{
    flex-grow:1;
    flex-shrink:1;
}
.top-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

}
.top-bar p{
  margin:8px;
}

/*----------3 dots ----------*/
.viewers-page .menu-nav {
    background-color:white;
    display: flex;
    justify-content: space-between;
  }
  
  .viewers-page .three-dots:after {
    cursor: pointer;
    color: black;
    content: '\22EE';
    font-size: 20px;
    padding: 0 5px;
  }
  
 .viewers-page .dropdown a {
    text-decoration: none;
    color:#5E5E5E;
    font-size: 12px;
    padding:0px;
  }
.viewers-page .dropdown a div{
    padding:5px 10px;
    border:.5px solid rgb(219, 215, 215);
}
.viewers-page .like_card{
    flex-direction: row;
    height: 100px;
}
.viewers-page .dropdown {
    position: absolute;
    right: 40px;
    top: 20px;
    background-color:white;
    border:1px solid rgb(224, 223, 223);
    outline: none;
    opacity: 0;
    /*z-index: -1;
    max-height: 0;
    transition: opacity 0.1s, z-index 0.1s; max-height: 5s;*/
    text-align: center;
    padding:0px;
  }
.viewers-page .cancel{
    color:#D90000;
    background-color:#f5f5f5;
  }
 .viewers-page .cancel:hover{
    color:#D90000;
  }
  
.viewers-page .dropdown-container:focus {
    outline: none;
  }
  
.viewers-page .dropdown-container:focus .dropdown {
    opacity: 1;
    z-index: 100;
    max-height: 100vh;
    transition: opacity 0.2s, z-index 0.2s; max-height: 0.2s;
  }
  .display{
    display: none;
  }
  .display1{
    display:block;
  }
  .card_footer{
    text-align: center;
  }
  .display2{
  display: none !important;
  }
  /* .viewers_name{
    position: absolute;
    bottom: 2rem;
  }
  .viewers_loc{
    position: absolute;
    bottom: 0px;
  } */
  .viewers_data{
    width: 36% !important;
  }
  .viewers_icon{
    position: absolute;
    width: 100%;
    background-image: url('../../assets/images/icons_bg.png');
    bottom: 0;
    background-size: contain;  
  }

  .close_button {
    cursor: pointer;
  }
  
  .close-button {
    margin: 0px auto;
    -webkit-transition: -webkit-transform 1s ease-in-out;
    transition: transform 1s ease-in-out;
    transform-origin: center;
    transform-style: preserve-3D;
  }
  
  .close-button:hover {
    -webkit-transform: rotate(90deg);
    transform: rotate(180deg);
  }
  @media (min-width: 1920px) {
    .viewers_data {
      width: 25% !important;
  }
  }
  @media screen and (max-width: 768px) {
    .viewers_data {
      width: 0% !important;  
    }
    .number-slide{
      width: auto;;
    }
    
  }

  .profile_div {
    right: 0px;
    position: fixed;
    width: 400px;
    height: calc(100vh - 80px); /* Ensure spaces around the minus sign */
    overflow: auto;
  }
  
  
  @media only screen and (max-width: 600px) {
    .top-bar {
      z-index: 0;
    }
  
    .profile_div {
      top: 0px;
      right: 0px;
      position: fixed;
      width: 100%;
      height: 100%;
      overflow: auto;
      min-height: -webkit-fill-available;
    }
  
    .notifications_card {
      width: 100% !important;
    }
  }