.user_info {
  border-radius: 8px;
  height: fit-content;

}

.chat-page {
  flex-grow: 1;
  width: 100%;
  flex-shrink: 1
}

.user_info {
  flex-grow: 1;
  flex-shrink: 1;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

}

.top-bar p {
  margin: 8px;
}

.chat_card {
  flex-direction: row;
  padding: 0px;
  /* code to be updated */
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
  /* code to be updated */
  border: 1px solid rgba(0, 0, 0, 0.10);
  text-align: start;
}

.card-header {
  padding: .5rem 1rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 0px solid rgba(0, 0, 0, .125);
}

.card-footer {
  padding: .5rem 1rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 0px solid rgba(0, 0, 0, .125);
}

.profile_name {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.chat-card .card-footer {
  border-top: 0px solid rgba(0, 0, 0, .125);
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
}

.message_count {
  border-radius: 10px;
  font-size: 10px;
  background-color: #D90000;
  color: white;
  width: 20px;
  margin: 0px;
  padding: 2px;
  text-align: center;

}

.timer {
  font-size: 12px;
  margin-bottom: 30px;
}

.searchbar {
  border-radius: 14px;
  padding: 5px;
  border: 0px;
  padding-left: 20px;
  margin: 10px;
  outline: none;
}

.chat_img {
  border-radius: 50%;
  height: 35px;
  margin-top: -20px;
  border: 1px solid black;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}


.msg {
  display: flex;
  padding: 10px 10px 10px 10px;
  margin: 15px;
  border-radius: 3000px;
  box-shadow: 0 0 10px rgb(164, 164, 164);
  align-items: center;
}

.msg p {
  margin-bottom: 0px;
}

.sent {
  background-color: #50555C;
  color: white;
  border-radius: 20px 20px 0px 20px;
  flex-direction: row-reverse;
  text-align: end;
  float: right;
}

.received {
  border: 1px solid #FFD3D3;
  background-color: #FFD3D3;
  border-radius: 20px 20px 20px 0px;
  float: left;
}

.sendMsg {
  position: fixed;
  display: flex;
  width: 33%;
  bottom: 0;
  z-index: 1;
  border-top: 1px solid lightgray;
  margin-left: -16px;
  padding: 10px;
  padding-bottom: 20px;
  background-color: #fafafa;
  justify-content: space-around;
}

.msgs {
  margin: 75px 0;
  display: flex;
  flex-direction: column;
}


@media (max-width: 775px) {
  .sent {
    padding: 10px 10px 10px 10px;
  }

  .received {
    padding: 10px 10px 10px 10px;
  }

}

@media (max-width: 500px) {
  .sent {
    padding: 7px 7px 7px 7px;
  }

  .received {
    padding: 7px 7px 7px 7px;
  }

}

.chat_bg {
  background-color: #ffffff;
}

.chat_pic {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  object-fit: contain;
  background-color: #50555C;
}

.chat_head {
  position: fixed;
  width: 100%;
  background-color: #fff;
  padding: 10px;
  border-bottom: 1px solid #cacaca;
  z-index: 1;
}

.chat_name {
  margin-left: 10px;
}

.chat_send {
  background: none;
  border: none;
}

.chat_send img {
  width: 33px;
}

.chat_send:hover {
  background-color: white !important;
}

.chat_send:disabled {
  background-color: white !important;
}

.online_status {
  position: absolute;
  left: 5rem;
  bottom: 0.6rem;
  font-size: 13px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 20px;
  font-family: roboto, arial;
  color: white;
  line-height: 40px;
  text-align: center;
  background: #D90000;
}

.avatar_1 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 20px;
  font-family: roboto, arial;
  color: white;
  line-height: 50px;
  text-align: center;
  background: #D90000;
}

.chat_time {
  font-size: 11px;
}

.chat_scroll_data {
  background-color: #50555c;
  color: #fff;
  padding: 5px;
  border-radius: 10px;
}

.green_dot {
  height: 8px;
  width: 8px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}

.red_dot {
  height: 8px;
  width: 8px;
  background-color: #D90000;
  border-radius: 50%;
  display: inline-block;
}

.text-bold{
  font-size: 16px;
  font-weight: bold;
}

.font-12{
  font-size: 12px;
}
@media (max-width: 775px) {

  .profile_div {
    right:0px;
    position: fixed;
    width: 100%;
  }

  .sent {
    padding: 10px 10px 10px 10px;
  }

  .received {
    padding: 10px 10px 10px 10px;
  }

  .msgs {
    margin: 0px;
    display: flex;
    flex-direction: column;
  }

  .chat_head {
    position: relative;
    width: 100%;
    background-color: #fff;
    padding: 10px;
    border-bottom: 1px solid #cacaca;
    z-index: 1;
  }

  .sendMsg{
    width: 100%;
    padding-bottom: 5px;
    padding-top: 5px;
  }

}