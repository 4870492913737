.side_menu ul {
    display: block;
}
.head-div {
    background-color: #D90000;
}
.side_li_a {
    color: #AAABAB !important;
}
.sidebar-content {
    background-color: white;
}
.gAMYCL {
    border-bottom: 0px solid hsla(0,0%,67.8%,0.2) !important;
}
button.logout_btn.btn.btn-primary {
    background-color: white !important;
    color: #aaabab;
    border-color: snow;
}
.log_btn {
    margin: 0px 7px !important;
}
.modal-header.clse_btn{
    justify-content: flex-end;
}
.profile_name{
    margin-left: 1rem;
}
button.logout_btn.btn.btn-primary{
    padding: 0px;
    /* margin: 0px 8px; */
}
.side_li_a {
    list-style: none;
    margin: 10px 0px !important;
}
li.side_li_a:hover {
    background-color: #D90000;
    color: white !important;
}


 /* .active{
  background-color:#D90000;
  color:#fff;

} */
.activeClicked{
    background-color:#D90000;
    color:#fff;
} 
.LeBlV.toggled .item-content {
    max-width: 40px;
    display: block !important;
    overflow: hidden;
}
/* span.item-content {
    display: block !important;
    max-width: 40px !important;
    overflow: hidden;
} */
.dbXSUh.toggled .item-content{
    max-width: 40px;
    display: block !important;
    overflow: hidden;
}
ul a.side_li.active li{
    background-color:#D90000;
    color:#fff !important;
}
/* .active li.ihLnEL{
    background-color:#D90000;
    color:#fff;
} */
 img.notSelect {
    display: inline;
}

 img.selected {
    display: none;
}

.active img.selected {
    display: inline;
}

.active img.notSelect {
    display: none;
}
.side-icon{
    width: auto !important;
    margin-right: 20px !important;
}
.sc-cOFTSb.gAMYCL{
    position: sticky;
    top: 0;
    z-index: 1;
}
span.head-text{
    display: flex;
}
span.head-text img{
    margin-right: 10px !important;
}
a.unique_id{
    text-decoration: none;
    color: white;
}
.side_menu {
    background-color: white;
}
.side-icon.notSelect.edit {
    width: 25px !important;
}
.count_data{
    padding: 4px 7px;
    background-color: #D90000;
    border-radius: 20px;
    color: white;
    font-size: 12px;
}

.gAMYCL .head-div {
    padding: 20px 20px !important;
    font-size: 16px !important;
}

.side_menu{
    padding: 0px !important;
}

.side_li_a {
    list-style: none;
    margin: 0px 0px !important;
}
.display-name{
white-space:break-spaces;
}
.mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #D90000;
    
}
.profile-section {
    display: flex;
    align-items: center;
}
.sidebar-toggle {
    font-size: 1.5rem;
    background: none;
    border: none;
    color: #000;
    cursor: pointer;
}
@media (max-width: 768px) {

    .card-body{
        padding: 5px !important;
        
    }
    .sidebar {
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 999;
      transform: translateX(-100%);
      background-color: white;
      color: black;
      transition: transform 0.3s ease;
    }
    
    .sidebar.visible {
      transform: translateX(0);
    }
    .page_right{
        margin-left: 0px !important;
    }
    .page_left {
        position: initial !important;
    }
    .head-div{
        display: none !important;
    }
    .display-name, .unique_id{
        font-weight: 500;
        margin-left: 1rem;
    }
    .top-bar{
        padding: 10px 8px!important;
    }
  }