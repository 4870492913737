.display{
    display: none;
  }
  .display1{
    display:block;
  }
  .card_footer{
    text-align: center;
  }
  .display2{
  display: none !important;
  }
  .skipped_icon{
    position: absolute;
    width: 100%;
    background-image: url('../../assets/images/icons_bg.png');
    bottom: 0;
    background-size: contain;
  }
  .pl_lock.ns {
    position: absolute;
    bottom: 2.5rem;
    background: #747474ab;
    right: 0.5rem;
    width: 40px;
}

.profile_div {
  right: 0px;
  position: fixed;
  width: 400px;
  height: calc(100vh - 80px); /* Ensure spaces around the minus sign */
  overflow: auto;
}


@media only screen and (max-width: 600px) {
  .top-bar {
    z-index: 0;
  }

  .profile_div {
    top: 0px;
    right: 0px;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: auto;
    min-height: -webkit-fill-available;
  }

  .notifications_card {
    width: 100% !important;
  }
}