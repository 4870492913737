.user_info {
    border-radius: 8px;
    height: fit-content;
}
.matches-page{
    flex-grow: 1;
    width: 100%;
    flex-shrink:1
}
.user_info{
    flex-grow:1;
    flex-shrink:1;
}
.top-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

}
.top-bar p{
    margin:8px;
  }
.matches_card{
    flex-direction: row;
    height: 100px;
    text-align: left;
    flex-grow: 1;
    width: 50%;
    padding:0 px;
    border-radius: 15px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
}
.matches_card:hover{
    background-color: #ffdddd;
}

.matches_card:active{
  background-color: #ffdddd;
}

.matches-page .card-header {
    padding:.5rem 1rem;
    margin-bottom: 0;
    border-bottom: 0px solid rgba(0,0,0,.125);
}
.matches-page .card-footer {
    padding: .5rem 1rem;
    margin-bottom: 0;
    border-bottom: 0px solid rgba(0,0,0,.125);
}
.profile_name{
    margin-left: 0px;
}
/*-----------3 dots -----*/
.matches-page .menu-nav {
    background-color:white;
    display: flex;
    justify-content: space-between;
  }
  
  .matches-page .three-dots:after {
    cursor: pointer;
    color: black;
    content: '\22EE';
    font-size: 20px;
    padding: 0 5px;
  }
  
  .matches-page .dropdown a {
    text-decoration: none;
    color:#5E5E5E;
    font-size: 12px;
   padding:0px;
  }
  .matches-page .dropdown a div{
    padding:5px 10px;
    border:.5px solid rgb(219, 215, 215);
}
.matches-page .like_card{
    flex-direction: row;
    height: 100px;
}
.matches-page .dropdown {
    position: absolute;
    right: 40px;
    top: 20px;
    background-color:white;
    border:1px solid rgb(224, 223, 223);
    outline: none;
    opacity: 0;
    /*z-index: -1;
    max-height: 0;
    transition: opacity 0.1s, z-index 0.1s; max-height: 5s;*/
    text-align: center;
    padding:0px;
  }
  .matches-page .cancel{
    color:#D90000;
    background-color:#f5f5f5;
  }
  .matches-page .cancel:hover{
    color:#D90000;
  }
  
  .matches-page .dropdown-container:focus {
    outline: none;
  }
  
  .matches-page .dropdown-container:focus .dropdown {
    opacity: 1;
    z-index: 100;
    max-height: 100vh;
    transition: opacity 0.2s, z-index 0.2s; max-height: 0.2s;
  }

/*-----------------------------------------*/
.dropdown a{
  cursor: pointer;
}

/*-----------------*/
h1,h4{
  text-align: center;
}
#remote-container video{
  height: auto;
  position: relative !important;
}
#me{
  position: relative;
  width: 50%;
  margin: 0 auto;
  display: block;
}
#me video{
  position: relative !important;
}
#remote-container{
  display: flex;
}
.video_user{
  position: absolute;
  bottom: 3rem;
  z-index: 1;
}
div#agora-video-player-track-cam{
  background-color: none !important;
}
.call_head{
  background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
display: block;
}
.call_head p{
  margin-bottom: 0px;
  display: block;
  text-align: center;
  font-size: 18px;
}
.call_img{
  width: 120px;
  height: 120px;
  display: block;
  margin: auto;
  border-radius: 80px;
  background-color: #5E5E5E;
  object-fit: contain;
  margin-top: 3rem;
}

.profile_img{
  width: 80px;
  height: 80px;
  object-fit: contain;
  border-radius: 50%;
  background-color: #5E5E5E;
}

.incoming_call_text{
  font-weight: bold;
  font-size: 18px !important;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #000000c7 !important;
  margin: 0px !important;
}


.call_button{
  width: 50px;
  height: auto;
  object-fit: contain;
}

.video_footer{
  padding: 0px;
}
.agora_video_player{
  object-fit: contain !important;
}
.modal_btns{
  background-color: white;
  border: none;
}
/* .call_body{
  background-image: url('../../assets/images/membership_bg.png');
} */
.cal_st{
  font-weight: 500;
  color: black;
  font-size: 16px !important;
}

.profile_div {
  right: 0px;
  position: fixed;
  width: 400px;
  height: calc(100vh - 80px); /* Ensure spaces around the minus sign */
  overflow: auto;
}


@media only screen and (max-width: 767px) {
  
  .profile_div {
    top: 0px;
    right: 0px;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: auto;
    min-height: -webkit-fill-available;
  }

  .matches_card {
    width: 100%;
  }
}

.profile_div{
  right: '0px';
  position: fixed;
}

@media only screen and (max-width: 600px) {
  .profile_div{
      right: '0px';
      position: fixed;
      width: 100% ;
  }

  ul {
    padding-left: 0rem !important;
  }
}

