.selfie_page{
    background-image: url('../../assets/images/selfie_bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100vh;
}
.webcam-img img{
    border-radius: 20px;
}
.webcam-img video{
    border-radius: 20px;
}
.selfiePic{
    background-image: url('../../assets/images/selfie_bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* height: 100vh; */
}
.progress.active .progress-bar {
    -webkit-transition: none !important;
    transition: none !important;
}
.progress-bar{
    background-color: #D90000;
    border-radius: 0px 0px 10px 0px;
}
.progress.progress-striped.active{
    position: relative;
    top: -32px;
    right: 6rem;
}
.progress.progress-striped.active{
    width: 100%;
    border-radius: 0px;
}
.progress{
    height: 0.7rem;
}
.webcam-img img {
    width: 80%;
}

/* Styling for the custom "Choose File" button */
.custom-file-input-btn {
  background-color: #D90000;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
  border: none;
  outline: none;
}

.custom-file-input-btn:hover {
  background-color: #555;
}

/* Optional: Styling the text showing selected file name */
.custom-file-input-text {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
}
